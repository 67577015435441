.hide {
    width: 96px;
    height: 96px;
    animation: crownFadeIn 0.5s ease-in, crownPop 1.6s ease-out 0.5s;
}

@keyframes crownFadeIn {
    from {
        transform: translateY(60px) scale3d(0, 0, 0);
        opacity: 0;
    }
    to {
        transform: translateY(15px) scale3d(0.9, 0.9, 0.9);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes crownPop {
    0% {
        transform: translateY(15px) scale3d(0.9, 0.9, 0.9);
    }

    9% {
        transform: translateY(0px) scale3d(1.07, 1.07, 1.07);
    }

    25% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    50% {
        transform: scale3d(1, 1, 1);
    }

    75% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes gradientPop {
    0% {
        transform: scale3d(1, 1, 1);
    }

    25% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    50% {
        transform: scale3d(1, 1, 1);
    }

    75% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.backdropContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 18000;
    animation: changeBg 2400ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
}

.backdropContainer div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

@keyframes changeBg {
    0%,
    100% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
}

.big {
    background-image: radial-gradient(
        circle at 50% 50%,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 100%
    );
    opacity: 0;
    mask: radial-gradient(circle at 50%, transparent, rgb(238, 241, 11, 0.5) 192px);
    z-index: 19999;
    animation: fadeIn 0.5s ease-in-out forwards, gradientPop 1.6s 0.5s ease-in-out;
}

.small {
    background-image: radial-gradient(
        circle at 50% 50%,
        rgba(255, 255, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 0) 100%
    );
    opacity: 0;
    mask: radial-gradient(circle at 50%, transparent, rgb(238, 241, 11, 0.5) 96px);
    z-index: 20000;
    animation: fadeIn 0.5s 0.3s ease-in-out forwards, gradientPop 1.6s 0.8s ease-in-out;
}

.smallLight {
    background-image: radial-gradient(
        circle at 50% 50%,
        rgba(255, 255, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.1) 25%,
        rgba(0, 0, 0, 0) 100%
    );
}

.bigLight {
    background-image: radial-gradient(
        circle at 50% 50%,
        rgba(255, 255, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
    );
}
