
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
}
a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 6px;
    opacity: 0;
}

::-webkit-scrollbar-track {
    opacity: 0;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--bg);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="color"] {
    width: 26px;
    height: 26px;
    border: none;
    border-radius: 26px;
    background: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 26px;
}

.main-layout {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100);
}
