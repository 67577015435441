.bounce {
    animation: bounce 1.2s ease-in-out infinite both;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}
